import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div style={{ margin: "auto", maxWidth: "70ch" }}>
      <h1 style={{ padding: "1rem 1rem 0" }}>404: Not Found</h1>
      <p style={{ padding: "0 1rem" }}>
        We did not find the page you’re looking for.
      </p>
      <p style={{ padding: "0 1rem" }}>
        <Link to="/">Go to home page</Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
